import "./App.css";
import { random } from "./utils";
import { useEffect, useState } from "react";
import data from "./data";
import Timer from "./components/Timer/Timer";
import GenrePlaylist from "./components/GenrePlaylist/GenrePlaylist";
import { genrePlayList } from "./constants";
import YoutubePlayer from "./components/Player/YoutubePlayer";

function App() {
  const [currentBackground, setCurrentBackground] = useState(() => {
    const index = random(0, data.length - 1);
    return data[index];
  });

  const [selectedGenre, setSelectedGenre] = useState(
    () => Object.keys(genrePlayList)[0]
  );

  const playlistId = genrePlayList[selectedGenre] || "";

  useEffect(() => {
    const imageInterval = setInterval(() => {
      const index = random(0, data.length - 1);
      setCurrentBackground(data[index]);
    }, 5000);

    return () => clearInterval(imageInterval);
  }, []);

  return (
    <div className="app">
      <img
        src={currentBackground}
        alt="Girl in a jacket"
        className="full-screen-image"
      />
      <GenrePlaylist
        selectedGenre={selectedGenre}
        onSelectGenre={setSelectedGenre}
      ></GenrePlaylist>
      <div id="content">
        <Timer></Timer>
        <YoutubePlayer playlistId={playlistId}></YoutubePlayer>
      </div>
    </div>
  );
}

export default App;
