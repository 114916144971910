import "./assets/imgs/amp_prob.gif";
import "./assets/imgs/attack.gif";
import "./assets/imgs/bad_landing.gif";
import "./assets/imgs/bicycle.gif";
import "./assets/imgs/blade.gif";
import "./assets/imgs/bluebalcony.gif";
import "./assets/imgs/bridge.gif";
import "./assets/imgs/bridge_raining.gif";
import "./assets/imgs/cacao_and_coffee_shop.gif";
import "./assets/imgs/castle.gif";
import "./assets/imgs/cave.gif";
import "./assets/imgs/cemetry.gif";
import "./assets/imgs/citymirror.gif";
import "./assets/imgs/coast.gif";
import "./assets/imgs/coffeeinrain.gif";
import "./assets/imgs/comition_sky_left_to_right.gif";
import "./assets/imgs/controlroom.gif";
import "./assets/imgs/daftpunk.gif";
import "./assets/imgs/dark_pillar.gif";
import "./assets/imgs/dawn.gif";
import "./assets/imgs/drift.gif";
import "./assets/imgs/droidcrime.gif";
import "./assets/imgs/echoesfromneals.gif";
import "./assets/imgs/elderorc.gif";
import "./assets/imgs/exodus.gif";
import "./assets/imgs/factory5.gif";
import "./assets/imgs/falls.gif";
import "./assets/imgs/familydinner.gif";
import "./assets/imgs/fire.gif";
import "./assets/imgs/flower_shop.gif";
import "./assets/imgs/forrest.gif";
import "./assets/imgs/fortress.gif";
import "./assets/imgs/future.gif";
import "./assets/imgs/girlinrain.gif";
import "./assets/imgs/highfloor.gif";
import "./assets/imgs/highlands.gif";
import "./assets/imgs/highsoceity.gif";
import "./assets/imgs/horse.gif";
import "./assets/imgs/iplayoldgames.gif";
import "./assets/imgs/jazznight.gif";
import "./assets/imgs/lake.gif";
import "./assets/imgs/last_dance.gif";
import "./assets/imgs/lowlands.gif";
import "./assets/imgs/lullaby.gif";
import "./assets/imgs/metro_final.gif";
import "./assets/imgs/midnight_melancholy.gif";
import "./assets/imgs/moon.png";
import "./assets/imgs/mountain.gif";
import "./assets/imgs/mountain_mote.gif";
import "./assets/imgs/nature.gif";
import "./assets/imgs/nero_land.gif";
import "./assets/imgs/nightlytraining.gif";
import "./assets/imgs/nighttrain.gif";
import "./assets/imgs/pilot.gif";
import "./assets/imgs/player2.gif";
import "./assets/imgs/rain.gif";
import "./assets/imgs/redbicycle.gif";
import "./assets/imgs/reddriver.gif";
import "./assets/imgs/ride.gif";
import "./assets/imgs/robot_alley.gif";
import "./assets/imgs/sandcastle.gif";
import "./assets/imgs/sea.gif";
import "./assets/imgs/shootingstars.gif";
import "./assets/imgs/shop.gif";
import "./assets/imgs/sideshop.gif";
import "./assets/imgs/skate.gif";
import "./assets/imgs/snow.gif";
import "./assets/imgs/spacecommander.gif";
import "./assets/imgs/spaceport.gif";
import "./assets/imgs/stacking_houses_on_a_windy_day.gif";
import "./assets/imgs/streets.gif";
import "./assets/imgs/sushi.gif";
import "./assets/imgs/swamp.gif";
import "./assets/imgs/temple.gif";
import "./assets/imgs/thieves.gif";
import "./assets/imgs/town.gif";
import "./assets/imgs/train.gif";
import "./assets/imgs/train_city.gif";
import "./assets/imgs/troll_cave.gif";
import "./assets/imgs/tv.gif";
import "./assets/imgs/underwater.gif";
import "./assets/imgs/virtuaverse.gif";
import "./assets/imgs/wild_boy.gif";
import "./assets/imgs/windyday.gif";
import "./assets/imgs/youngatnight.gif";
import "./assets/imgs/zombies.gif";
import "./assets/imgs/ghibli.gif";
import "./assets/imgs/ghibli1.gif";
import "./assets/imgs/ghibli2.gif";
import "./assets/imgs/ghibli3.gif";
import "./assets/imgs/ghibli4.gif";
import "./assets/imgs/ghibli5.gif";
import "./assets/imgs/ghibli6.gif";
import "./assets/imgs/ghibli7.gif";
import "./assets/imgs/ghibli8.gif";
import "./assets/imgs/ghibli9.gif";
import "./assets/imgs/ghibli10.gif";
import "./assets/imgs/ghibli11.gif";
import "./assets/imgs/ghibli12.gif";
import "./assets/imgs/ghibli13.gif";
import "./assets/imgs/girls.gif";
import "./assets/imgs/hacker.gif";
import "./assets/imgs/horror_sale.gif";
import "./assets/imgs/kit.gif";
import "./assets/imgs/lofi.gif";
import "./assets/imgs/lofi.gif";
import "./assets/imgs/lofi1.gif";
import "./assets/imgs/lofi2.gif";
import "./assets/imgs/lofi3.gif";
import "./assets/imgs/lofi4.gif";
import "./assets/imgs/lofi5.gif";
import "./assets/imgs/lofi6.gif";
import "./assets/imgs/lofi7.gif";
import "./assets/imgs/lofi8.gif";
import "./assets/imgs/lofi9.gif";
import "./assets/imgs/lofi10.gif";
import "./assets/imgs/lofi11.gif";
import "./assets/imgs/lofi12.gif";
import "./assets/imgs/lofi13.gif";
import "./assets/imgs/lofi14.gif";
import "./assets/imgs/lofi15.gif";
import "./assets/imgs/lofi16.gif";
import "./assets/imgs/lofi17.gif";
import "./assets/imgs/lofi18.gif";
import "./assets/imgs/lofi19.gif";
import "./assets/imgs/lofi20.gif";
import "./assets/imgs/lofi21.gif";
import "./assets/imgs/lofi22.gif";
import "./assets/imgs/lofi23.gif";
import "./assets/imgs/lofi24.gif";
import "./assets/imgs/lofi25.gif";
import "./assets/imgs/lofi26.gif";
import "./assets/imgs/lofi27.gif";
import "./assets/imgs/lofi28.gif";
import "./assets/imgs/lofi29.gif";
import "./assets/imgs/lofi30.gif";
import "./assets/imgs/lofi31.gif";
import "./assets/imgs/lofi32.gif";
import "./assets/imgs/lofi33.gif";
import "./assets/imgs/lofi34.gif";
import "./assets/imgs/lofi35.gif";
import "./assets/imgs/lofi36.gif";
import "./assets/imgs/lofi37.gif";
import "./assets/imgs/lofi38.gif";
import "./assets/imgs/lofi39.gif";
import "./assets/imgs/lofi40.gif";
import "./assets/imgs/lofi41.gif";
import "./assets/imgs/lofi42.gif";
import "./assets/imgs/lofi43.gif";
import "./assets/imgs/lofi44.gif";
import "./assets/imgs/lofi45.gif";
import "./assets/imgs/lofi46.gif";
import "./assets/imgs/lofi47.gif";
import "./assets/imgs/lofi48.gif";
import "./assets/imgs/lofi49.gif";
import "./assets/imgs/lofi50.gif";
import "./assets/imgs/lofi51.gif";
import "./assets/imgs/lofi52.gif";

const backgroundLists = [
  "amp_prob.gif",
  "attack.gif",
  "bad_landing.gif",
  "bicycle.gif",
  "blade.gif",
  "bluebalcony.gif",
  "bridge.gif",
  "bridge_raining.gif",
  "cacao_and_coffee_shop.gif",
  "castle.gif",
  "cave.gif",
  "cemetry.gif",
  "citymirror.gif",
  "coast.gif",
  "coffeeinrain.gif",
  "comition_sky_left_to_right.gif",
  "controlroom.gif",
  "daftpunk.gif",
  "dark_pillar.gif",
  "dawn.gif",
  "drift.gif",
  "droidcrime.gif",
  "echoesfromneals.gif",
  "elderorc.gif",
  "exodus.gif",
  "factory5.gif",
  "falls.gif",
  "familydinner.gif",
  "fire.gif",
  "flower_shop.gif",
  "forrest.gif",
  "fortress.gif",
  "future.gif",
  "girlinrain.gif",
  "highfloor.gif",
  "highlands.gif",
  "highsoceity.gif",
  "horse.gif",
  "iplayoldgames.gif",
  "jazznight.gif",
  "lake.gif",
  "last_dance.gif",
  "lowlands.gif",
  "lullaby.gif",
  "metro_final.gif",
  "midnight_melancholy.gif",
  "moon.png",
  "mountain.gif",
  "mountain_mote.gif",
  "nature.gif",
  "nero_land.gif",
  "nightlytraining.gif",
  "nighttrain.gif",
  "pilot.gif",
  "player2.gif",
  "rain.gif",
  "redbicycle.gif",
  "reddriver.gif",
  "ride.gif",
  "robot_alley.gif",
  "sandcastle.gif",
  "sea.gif",
  "shootingstars.gif",
  "shop.gif",
  "sideshop.gif",
  "skate.gif",
  "snow.gif",
  "spacecommander.gif",
  "spaceport.gif",
  "stacking_houses_on_a_windy_day.gif",
  "streets.gif",
  "sushi.gif",
  "swamp.gif",
  "temple.gif",
  "thieves.gif",
  "town.gif",
  "train.gif",
  "train_city.gif",
  "troll_cave.gif",
  "tv.gif",
  "underwater.gif",
  "virtuaverse.gif",
  "wild_boy.gif",
  "windyday.gif",
  "youngatnight.gif",
  "zombies.gif",
  "ghibli.gif",
  "ghibli1.gif",
  "ghibli2.gif",
  "ghibli3.gif",
  "ghibli4.gif",
  "ghibli5.gif",
  "ghibli6.gif",
  "ghibli7.gif",
  "ghibli8.gif",
  "ghibli9.gif",
  "ghibli10.gif",
  "ghibli11.gif",
  "ghibli12.gif",
  "ghibli13.gif",
  "girls.gif",
  "hacker.gif",
  "horror_sale.gif",
  "kit.gif",
  "super_mario_bros.gif",
  "lofi.gif",
  "lofi1.gif",
  "lofi2.gif",
  "lofi3.gif",
  "lofi4.gif",
  "lofi5.gif",
  "lofi6.gif",
  "lofi7.gif",
  "lofi8.gif",
  "lofi9.gif",
  "lofi10.gif",
  "lofi11.gif",
  "lofi12.gif",
  "lofi13.gif",
  "lofi14.gif",
  "lofi15.gif",
  "lofi16.gif",
  "lofi17.gif",
  "lofi18.gif",
  "lofi19.gif",
  "lofi20.gif",
  "lofi21.gif",
  "lofi22.gif",
  "lofi23.gif",
  "lofi24.gif",
  "lofi25.gif",
  "lofi26.gif",
  "lofi27.gif",
  "lofi28.gif",
  "lofi29.gif",
  "lofi30.gif",
  "lofi31.gif",
  "lofi32.gif",
  "lofi33.gif",
  "lofi34.gif",
  "lofi35.gif",
  "lofi36.gif",
  "lofi37.gif",
  "lofi38.gif",
  "lofi39.gif",
  "lofi40.gif",
  "lofi41.gif",
  "lofi42.gif",
  "lofi43.gif",
  "lofi44.gif",
  "lofi45.gif",
  "lofi46.gif",
  "lofi47.gif",
  "lofi48.gif",
  "lofi49.gif",
  "lofi50.gif",
  "lofi51.gif",
  "lofi52.gif",
];

export default backgroundLists.map((background) =>
  require(`./assets/imgs/${background}`)
);
