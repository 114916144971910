export const genrePlayList = {
  Anime: "PLl578ZPbYIlFcSxuka8Km37VgbUYUWI5p",
  Rock: "PLgIyxgBXHaQBbsXxYvJGw7MTRqS6QHWNP",
  Lofi: "PLuCUpg5b_vRqWMNwIH5oazz_qD170NtI4",
  Cyberpunk: "PLuCUpg5b_vRrPS1lisrBQ_tqwalDYQgPU",
  "Future Funk": "PLuCUpg5b_vRqfA55WHzL0PeEhKhj7wGY6",
  Electric: "PLuCUpg5b_vRqu8xgeJKKSzotMLDHoZr55",
  "Retro/Electro": "PL95cZBm_uQ2lzjNDET-5V4OhnB5FHQOZ5",
  "City Pop 80's": "PLUF4zzcb1zV5q9P3EI1cliYLj8Hp22KqN",
  "Japanese City Pop 80's": "PLOi9Cf_JAyXLlJ9Hpph56NAYet_bp_I9g",
  "USA City Pop": "PL-BTuxpixKjI2MvZ1YzLpPx0tqckp2cCy",
  "Korean City Pop": "PL5zX5DoJ2SMTOHUkEYRwnsVdWSt935VNP",
  "RnB & Neo Soul": "PLKYTmz7SemapTzn8hztdqgBnSv10mMt2h",
  "Future Bass": "PLKYTmz7SemaoV22tNvOWoAHb4r8irmYcE",
  Rap: "PLs2v9mqVMAyM_Dk4fG1v5ncynf5s0z1yg",
};
